import { Button, Card, Col, Image, Row } from "react-bootstrap";
import './Products.css'
import cartPhoto from './addToCart.png'
import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import UserContext from "../../ContextUser";

const Products = ({ infos }) => {
    const { userDetails, setUserDetails, } = useContext(UserContext);
    const { name, description, price, _id } = infos;
    const [cart, setCart] = useState([]);
    const [quantity, setQuantity] = useState(0);
    const [productId, setProductId] = useState('');
    const [refresher, setRefresher] = useState(false);
    
    const history = useHistory();

    useEffect(() => {
        setProductId(_id)
        setCart(userDetails.cart)
        console.log(userDetails)
        if (userDetails.id !== undefined) {
            console.warn(userDetails.id)
            if (userDetails.cart !== undefined) {
                console.log(userDetails.cart.length)
                for (let i = 0; i < userDetails.cart.length; i++) {
                    if (userDetails.cart[i].productId === _id) {
                        setQuantity(userDetails.cart[i].quantity);
                    }
                }
            } else {
                window.location.reload(true)
            }
        } 
        console.log(cart)
    }, [])

    useEffect(() => {
        console.log(cart)
        setUserDetails({
            id: userDetails.id,
            isAdmin: userDetails.isAdmin,
            cart: cart
        })
        const data = {
            method: 'PUT',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(cart)
        }
        fetch('https://ecomm-api.xhunn.dev/products/addToCart', data)
            .then(result => {
                if (result.ok) {
                    return result.json()
                } else {
                    throw new Error('Error occured')
                }
            }).then(cartResult => {
                if (cartResult) {
                    console.log('Success')
                } 
            }).catch(error => console.error(error))
    }, [refresher])

    const toCart = (event) => {
        event.preventDefault()
        const product = {
            productId: productId,
            quantity: quantity
        }
        setCart([...cart, product])
        if (refresher) {
            setRefresher(false)
        } else {
            setRefresher(true)
        }
    }
    
    const selected = (event) => {
        event.preventDefault()
        localStorage.setItem('search-item', event.currentTarget.value)
        setTimeout(() => {
            history.push('/home')
            history.push('/selected')
        }, 1000);
    }
    return (
        <>
            <Card className="mb-3 card-style">
                <Row>
                    <Col xs={3} className="m-auto">
                        <Image fluid src="sampleTwo.jpg" className="image d-flex align-items-center justify-content-center" />
                    </Col>
                    <Col>
                        <Card.Body className="ml-2 pl-0 mr-1 clickable">
                            <Card.Title className="item-link">
                                <Button className="button-title px-0" onClick={event => {
                                    selected(event)
                                }} value={productId}>
                                    {name.toUpperCase()}
                                </Button>
                            </Card.Title>
                            <Card.Subtitle className="mt-2">{description}</Card.Subtitle>
                            <Card.Footer className="mt-3 card-footer pt-2 px-0 pb-0">
                                <Row>
                                    <Card.Text as={Col} sm={12} md={3}>PHP {price}</Card.Text>
                                    {quantity <= 0 ? 
                                    <Card.Text as={Col} sm={12} md={3} className="text-right ml-auto"></Card.Text>:
                                    <Card.Text as={Col} sm={12} md={3} className="text-right ml-auto"> Quantity: {quantity}</Card.Text>
                                    }
                                    <Button as={Col} sm={12} md={1} className="mx-2 p-0 success d-flex align-items-center justify-content-center" onClick={() => {
                                        if (quantity >= 1) {
                                            return setQuantity(quantity - 1)
                                        }
                                    }}>-</Button>
                                    <Button as={Col} sm={12} md={1} className="mx-2 p-0 success d-flex align-items-center justify-content-center" onClick={() => setQuantity(quantity + 1)}>+</Button>
                                    <Button as={Col} sm={12} md={1} value={productId} className="mx-2 p-0 success d-flex align-items-center justify-content-center" onClick={event => toCart(event)}><Image height="20px" src={cartPhoto} alt="cart" /></Button>
                                </Row>
                            </Card.Footer>
                        </Card.Body>
                    </Col>
                </Row>
            </Card>
        </>
    );
}

export default Products;
import { Card, Container } from "react-bootstrap";

const Credits = () => {
    return (
        <>
            <Container className="mt-5">
                <Card className="mb-3">
                    <Card.Img src="home-wallpapersOne.png" alt="Home Wallpaper One" />
                    <Card.ImgOverlay>
                        <Card.Text><a href="https://wallpaperboat.com/wp-content/uploads/2020/04/orange-aesthetic-wallpaper-download-full-2.jpg">Wallpaper One (main)</a></Card.Text>
                    </Card.ImgOverlay>
                </Card>
                <Card className="mb-3">
                    <Card.Img src="home-wallpapersTwo.jpg" alt="Home Wallpaper Two" />
                    <Card.ImgOverlay>
                        <Card.Text><a href="https://wallpaperboat.com/wp-content/uploads/2020/04/minimalist-aesthetic-wallpaper-desktop.jpg">Wallpaper Two</a></Card.Text>
                    </Card.ImgOverlay>
                </Card>
                <Card className="mb-3">
                    <Card.Img src="home-wallpapersThree.jpg" alt="Home Wallpaper Three" />
                    <Card.ImgOverlay>
                        <Card.Text><a href="https://i.pinimg.com/originals/16/54/e7/1654e7cd7aaf92c0b140713de2e18e6e.jpg">Wallpaper Three</a></Card.Text>
                    </Card.ImgOverlay>
                </Card>
                <Card className="mb-3">
                    <Card.Img src="sampleOne.jpg" alt="Sample One" />
                    <Card.ImgOverlay>
                        <Card.Text><a href="https://wallpaperboat.com/wp-content/uploads/2020/04/orange-aesthetic-wallpaper-download-full-2.jpg">Sample Image One</a></Card.Text>
                    </Card.ImgOverlay>
                </Card>
                <Card className="mb-3">
                    <Card.Img src="sampleTwo.jpg" alt="Sample Two" />
                    <Card.ImgOverlay>
                        <Card.Text><a href="https://wallpaperboat.com/wp-content/uploads/2020/04/orange-aesthetic-wallpaper-download-full-2.jpg">Sample Image Two</a></Card.Text>
                    </Card.ImgOverlay>
                </Card>
            </Container>
        </>
    )
}

export default Credits;
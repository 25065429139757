//import AppNavbar from "../Components/AppNavbar/AppNavbar";
import { Container, Carousel} from "react-bootstrap";
import Banner from "../Components/Banner/Banner";
import Footer from "../Components/Footer/Footer";
import Highlights from "../Components/Highlights/Highlights";


let info = {
    title: "HOME",
    tagline: ""
}
const Home = () => {
    return (
        <>
            <Carousel variant="dark" className="m-n5 p-n0" indicators={false} nextIcon={''} prevIcon={''} fade>
                <Carousel.Item>
                    <img
                    className="d-block w-100"
                    src="home-wallpapersOne.png"
                    alt="First slide"
                    />
                    <Carousel.Caption className="h-50">
                        <h1 className="display-1">E L E G A N C E</h1>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                    <img
                    className="d-block w-100"
                    src="home-wallpapersTwo.jpg"
                    alt="Second slide"
                    />
                    <Carousel.Caption className="h-50">
                        <h1 className="display-1">P A S S I O N</h1>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                    <img
                    className="d-block w-100"
                    src="home-wallpapersThree.jpg"
                    alt="Third slide"
                    />
                    <Carousel.Caption className="h-50">
                        <h1 className="display-1">B E A U T Y</h1>
                    </Carousel.Caption>
                </Carousel.Item>
            </Carousel>
            <Footer/>
        </>
    );
}

export default Home;
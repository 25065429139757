import { useState, useContext, useEffect } from "react";
import { Form, Button, Container } from "react-bootstrap";
import Banner from "../Components/Banner/Banner";
import Footer from '../Components/Footer/Footer';
import './pages.css'
import UserContext from '../ContextUser'
import Swal from "sweetalert2";
import { Redirect } from "react-router";

let info = {
    title: "Welcome!",
    tagline: "Please login to proceed."
}
const Login = () => {
    // HOOKS 
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const { userDetails, setUserDetails } = useContext(UserContext)

    // CONDITION
    const isButtonActive = (email !== '' && password !== '');
    
    const loginDetails = {
			method: "POST",
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({
				email: email,
				password: password
			})
		}
    const authenticate = (event) => {
		event.preventDefault()

		fetch(`https://ecomm-api.xhunn.dev/users/login`, loginDetails)
            .then(res => res.json())
            .then(data => {
                console.log(data.accessToken)
                if (typeof data.accessToken !== 'undefined') {
                    localStorage.setItem('accessToken', data.accessToken);
                    retrieveUserDetails(data.accessToken)
                } else {
                    Swal.fire({
                        title: "Authentication Failed",
                        icon: 'error',
                        text: 'Already registered'
                    })
                }
            })
    }

    const retrieveUserDetails = (token) => {
        const authorization = {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${token}`
            }
        }
        fetch('https://ecomm-api.xhunn.dev/users/details', authorization)
            .then(promise => promise.json())
            .then(detailsOfUser => {
                setUserDetails({
                    id: detailsOfUser._id,
                    isAdmin: detailsOfUser.isAdmin
                })
            })
    }

    useEffect(() => {
        console.log(userDetails)
    }, [])
    
    
    return (
        (userDetails.id === null || userDetails.id === undefined) ?
            <>
                <Container fluid className="d-flex justify-content-center">
                    <img src="CartUp.png" height="100px" alt="CART-UP" className="mt-5" />
                </Container>
                <Container className="mb-5">
                    <Banner props={info}/>
                    <Form onSubmit={event => authenticate(event)}>
                        {/* Email */}
                        <Form.Group controlId="userEmail">
                            <Form.Label>Email Address:</Form.Label>
                            <Form.Control type="email" placeholder="Please enter your email here" value={email} onChange={value => setEmail(value.target.value)} className="text-bar" required/>
                        </Form.Group>

                        {/* Password */}
                        <Form.Group controlId="userPassword">
                            <Form.Label>Password:</Form.Label>
                            <Form.Control type="password" placeholder="Please enter your password here" value={password} onChange={value => setPassword(value.target.value)} className="text-bar" required/>
                        </Form.Group>
                        {/* Submit Button */}
                        {isButtonActive ? 
                            <Button type="submit" id="submitLogin" className="btn btn-block success">Login</Button> :
                            <Button type="submit" id="submitLogin" className="btn btn-block disabled" disabled>Login</Button>
                            
                        }
                    </Form>
                </Container>
                <Footer/>
            </> : <Redirect to="/shop" />
    );
}

export default Login;
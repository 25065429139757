import { Col, Container, Row } from 'react-bootstrap'

const Banner = ({ props }) => {
    let info = {
        title: props.title,
        tagline: props.tagline
    }
    return (
        <>
            <Row>
                <Col xs={12} className='py-5 mt-3 text-left'>
                    <h1>{info.title}</h1>
                    <p>{info.tagline}</p>
                </Col>
            </Row>
        </>
    );
}

export default Banner;
import { Col, Container, Row } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import linkedIn from './linkedin.png'
import github from './github.png'
import discord from './discord.png'
import './Footer.css'

const Footer = (props) => {
    return (
        <>
            <Container fluid className="bg-footer mt-5 py-3">
                <Row className="justify-content-center mt-4">
                    <Col xs={3}>
                        <h3>Credits</h3>
                    </Col>
                    <Col xs={3}>
                        <h3>About CART UP</h3>
                    </Col>
                    <Col xs={3}>
                        <h3>Follow Socials</h3>
                    </Col>
                </Row>
                <Row className="justify-content-center pb-2">
                    <Col xs={3}>
                        <Container fluid className="p-0">
                            <p>
                                Hello! Some contents in this page specifically images were taken from other websites. Please do refer to the <NavLink to="/credits" className="link-footer">credits</NavLink> for their sources.
                            </p>
                        </Container>
                    </Col>
                    <Col xs={3}>
                        <Container fluid className="p-0">
                            <p>
                                <strong>CART UP </strong> was created as for a capstone project to showcase the developer's skill. Check out <a href="https://www.linkedin.com/in/xhunn/" className="link-footer">Xhunn Raymundo</a> for more of his works in the future
                            </p>
                        </Container>
                    </Col>
                    <Col xs={3}>
                        <a href="https://www.linkedin.com/in/xhunn/">
                            <img height="35px" alt="linkedIn" src={linkedIn} />
                        </a>
                        <a href="https://github.com/xhunn" className="ml-2">
                            <img height="35px" alt="github" src={github}/>
                        </a>
                        <a href="https://discord.gg/ajps2gF" className="ml-2">
                            <img height="35px" alt="discord" src={discord}/>
                        </a>
                    </Col>
                </Row>
                <Row className="justify-content-center mt-5">
                    <Col xs={12} className="text-center">
                        <p className="lead">
                            &copy; 2021 CART UP
                        </p>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default Footer;
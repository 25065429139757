import { Col, Container, Row, Tabs, Tab } from "react-bootstrap";
import Archive from "../Components/AdminTools/Archive";
import Create from "../Components/AdminTools/Create";
import RetrieveAll from "../Components/AdminTools/Retrieve";
import Update from "../Components/AdminTools/Update";
import Footer from "../Components/Footer/Footer";

import { useContext, useEffect, useState } from 'react'
import UserContext from '../ContextUser'

import './pages.css';
import { Redirect } from "react-router";

const AdminPanel = () => {
    const { userDetails } = useContext(UserContext);
    const [refresh, setRefresh] = useState(false);
    const refresher = () => {
        if (refresh) {
            setRefresh(false)
        } else {
            setRefresh(true)
        }
    }
    useEffect(() => {
        console.log(userDetails)
        console.log(refresh)
    })
    return (
        (userDetails.isAdmin !== false) ?
            <>
                <Container fluid className="d-flex justify-content-center my-5">
                    <img src="CartUp.png" height="100px" alt="CART-UP" className="brand" />
                </Container>
                <Row className="d-flex align-items-center justify-content-around mt-5 py-5">
                    <Col xs={12} md={3} className="text-center">
                        <h1>Admin Tools</h1>    
                    </Col>
                    <Col xs={12} md={7}>
                        <Tabs defaultActiveKey="archive" className="admin" onSelect={refresher}>
                            <Tab eventKey="archive" title="Archive Product" className="p-5">
                                <Archive key={'archive'} refreshTab={refresh}/>
                            </Tab>
                            <Tab eventKey="retrive" title="Retrieve All Products" className="p-5">
                                <RetrieveAll key={'retrieve'} refreshTab={refresh} />
                            </Tab>
                            <Tab eventKey="create" title="Create product" className="p-5">
                                <Create key={'create'} refreshTab={refresh} />
                            </Tab>
                            <Tab eventKey="update" title="Update product" className="p-5">
                                <Update key={'update'} refreshTab={refresh} />
                            </Tab>
                        </Tabs>
                    </Col>
                </Row>
                <Footer/>
            </> : <Redirect to="/error"/>
    );
}

export default AdminPanel;
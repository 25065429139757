import { useState, useEffect } from "react";
import { Col, ListGroup, Row } from "react-bootstrap";
import { useHistory } from "react-router";
import './adminTools.css'

const RetrieveAll = (refresTab) => {
    const [products, setProducts] = useState([]);
    const [render, setRender] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState('');
    const history = useHistory();
    localStorage.setItem('search-item', selectedProduct)

    const select = (event) => {
        event.persist()
        setSelectedProduct(event.currentTarget.value)
        setTimeout(() => {
            history.push('/home')
            history.push('/selected')
        }, 1000);
    }


    useEffect(() => {
        fetch('https://ecomm-api.xhunn.dev/products/all')
            .then(dataFetch => dataFetch.json())
            .then(productsJSON => setProducts(productsJSON.map(eachProduct => {
            return (
                <>
                    <ListGroup.Item action onClick={select} className="product-item" key={eachProduct._id} value={eachProduct._id}>
                        <Row>
                            <Col>
                                {eachProduct.name}
                            </Col>
                            <Col className="text-right">
                                {eachProduct.isActive ?
                                    <strong className="text-success">Active</strong>:
                                    <strong className="text-danger">Inactive</strong>}
                            </Col>
                        </Row>
                    </ListGroup.Item>
                </>
            );
            })))
        setRender(products)
    }, [selectedProduct, refresTab])
    return (
        <>
            <Row className="mt-3 pt-3">
                <Col sm={12} md={3} className="d-flex align-items-center" >
                    <p className="lead text-center">All Products</p>
                </Col>
                <Col className="col-right" sm={12} md={8}>
                    <ListGroup className="product-item">
                        {render && products}
                    </ListGroup>
                </Col>
            </Row>
        </>
    )
}

export default RetrieveAll;
import { useContext, useEffect, useState } from "react";
import { Col, ListGroup, Row } from "react-bootstrap";
import UserContext from "../../ContextUser";
import './adminTools.css'

const Archive = (refreshTab) => {
    const [availableProducts, setAvailableProducts] = useState([]);
    const [unavailableProducts, setUnavailableProducts] = useState([]);
    const [renderOne, setRenderOne] = useState([]);
    const [renderTwo, setRenderTwo] = useState([]);
    const [refresh, setRefresh] = useState(false)
    const { userDetails } = useContext(UserContext)

    const archiveProduct = (id) => {
        const data = {
            method: "PUT",
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                id: id
            })
        }
        fetch('https://ecomm-api.xhunn.dev/products/archive', data)
            .then(result => {
                if (!result.ok) {
                    throw new Error('Something went wrong');
                } else {
                    return result.json()
                }
            })
            .then(result => {
                // alert(result.message)
                if (refresh) {
                    console.warn(result.message)
                    setRefresh(false)
                } else {
                    console.warn(result.message)
                    setRefresh(true)
                }
            }).catch(error => {
                console.log(error)
            })
    }

    useEffect(() => {
        fetch('https://ecomm-api.xhunn.dev/products/available')
            .then(result => {
                if (!result.ok) {
                    throw new Error('Something went wrong');
                } else {
                    return result.json()
                }
            })
            .then(allProducts => {
                setAvailableProducts(allProducts.map(eachProduct => {
                    return (
                        <>
                            <ListGroup.Item action onClick={event =>
                                archiveProduct(event.currentTarget.value)
                            } value={eachProduct._id} className="product-item">
                                {eachProduct.name}
                            </ListGroup.Item>
                        </>
                    );
                }))
            }).catch(error => {
                console.log(error)
            })
        setRenderOne(unavailableProducts)
    }, [renderOne, refresh, refreshTab])

    useEffect(() => {
        fetch('https://ecomm-api.xhunn.dev/products/unavailable')
            .then(result => {
                if (!result.ok) {
                    throw new Error('Something went wrong');
                } else {
                    return result.json()
                }
            })
            .then(allProducts => {
                setUnavailableProducts(allProducts.map(eachProduct => {
                    return (
                        <>
                            <ListGroup.Item action onClick={event =>
                                archiveProduct(event.currentTarget.value)
                            } value={eachProduct._id} className="product-item">
                                {eachProduct.name}
                            </ListGroup.Item>
                        </>
                    );
                }))
            }).catch(error => {
                console.log(error)
            })
        setRenderTwo(availableProducts)
    }, [renderTwo, refresh, refreshTab])

    return (
        <>
            <Row className="mt-3 pt-3">
                <Col sm={12} md={3} className="d-flex align-items-center" >
                    <p className="lead text-center">Please click product to <strong className="text-success">activate</strong></p>
                </Col>
                <Col className="col-right" sm={12} md={8}>
                    <ListGroup className="product-item">
                        {renderOne && unavailableProducts}
                    </ListGroup>
                </Col>
            </Row>
            <Row className="mt-5 pt-5 row-bottom">
                <Col sm={12} md={3} className="d-flex align-items-center" >
                    <p className="lead text-center">Please click product to <strong className="text-danger">deactivate</strong></p>
                </Col>
                <Col className="col-right" sm={12} md={8}>
                    <ListGroup className="product-item">
                        {renderTwo && availableProducts}
                    </ListGroup>
                </Col>
            </Row>
        </>
    )
}

export default Archive;
import { Navbar, Nav, Image, Form, Button, Row, ListGroup, Col } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import logo from './logo.png'
import './AppNavbar.css';
import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import UserContext from "../../ContextUser";
// #00ffc0 >> Color Scheme

const AppNavbar = () => {
    const [searchProduct, setSearchProduct] = useState('');
    const [availableProducts, setAvailableProducts] = useState([]);
    const [matches, setMatches] = useState([]);
    const [selectedSearch, setSelectedSearch] = useState('');
    const { userDetails } = useContext(UserContext);

    const history = useHistory();

    useEffect(() => {
        fetch('https://ecomm-api.xhunn.dev/products/available')
            .then(result => result.json())
            .then(data => setAvailableProducts(data));
    }, []);

    const selected = (event) => {
        event.preventDefault();
        localStorage.setItem('search-item', event.currentTarget.value)
        setTimeout(() => {
            history.push('/home')
            history.push('/selected')
        }, 1000);
    }
    
    const search = (product) => {
        let matches = [];
        for (let index = 0; index < availableProducts.length; index++) {
            if (availableProducts[index].name.toLowerCase().includes(product.toLowerCase())) {
                matches.push(availableProducts[index])
            }
        }
        for (let i = 0; i < 4; i++) {
            setMatches(matches.map(matchedSearch => {
                return (
                    <>
                        <ListGroup.Item className="search-values" width={100} action onClick={event => {
                            setSearchProduct('');
                            selected(event);
                        }} value={matchedSearch._id} >{matchedSearch.name}</ListGroup.Item>
                    </>
                )
            }));
        }
    }

    return (
        <>
            <Navbar className="background-nav sticky-top" expand="lg">
                <Navbar.Brand as={NavLink} to="/" className="ml-2">
                    <Image src={logo} width="50" height="50" className="d-inline-flex align-top" alt="Cart-Up"/>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav"/>
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="ml-auto" />
                    <Nav className="mx-3">
                        {userDetails.id === null || userDetails.id === undefined ?
                            <>
                                <Nav.Link as={NavLink} to="/register" className="link">Sign up</Nav.Link>
                                <Nav.Link as={NavLink} to="/login" className="link">Login</Nav.Link>
                                <Nav.Link as={NavLink} to="/shop" className="link">Shop</Nav.Link>
                            </>:
                            userDetails.isAdmin?
                                <>
                                    <Nav.Link as={NavLink} to="/shop" className="link">Shop</Nav.Link>
                                    <Nav.Link as={NavLink} to="/adminTools" className="link">Tools</Nav.Link>
                                    <Nav.Link as={NavLink} to="/logout" className="link">Logout</Nav.Link>
                                    <Nav.Link as={NavLink} to="/cart" className="link">Cart</Nav.Link>
                                </>:
                                <>
                                    <Nav.Link as={NavLink} to="/cart" className="link">Cart</Nav.Link>
                                    <Nav.Link as={NavLink} to="/shop" className="link">Shop</Nav.Link>
                                    <Nav.Link as={NavLink} to="/logout" className="link">Logout</Nav.Link>
                                </>
                        }
                    </Nav>
                    <Nav className="mx-3">
                        <Form className="d-flex">
                            <Form.Control type="search" placeholder="Search" className="mr-2 search-bar" value={searchProduct} onChange={event => {
                                setSearchProduct(event.target.value)
                                search(searchProduct)
                            }}>
                            </Form.Control>        
                            <Button className="search-button">
                                <img alt="svgImg" src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHg9IjBweCIgeT0iMHB4Igp3aWR0aD0iMjQiIGhlaWdodD0iMjQiCnZpZXdCb3g9IjAgMCAxNzIgMTcyIgpzdHlsZT0iIGZpbGw6IzAwMDAwMDsiPjxnIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0ibm9uemVybyIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIHN0cm9rZS1saW5lY2FwPSJidXR0IiBzdHJva2UtbGluZWpvaW49Im1pdGVyIiBzdHJva2UtbWl0ZXJsaW1pdD0iMTAiIHN0cm9rZS1kYXNoYXJyYXk9IiIgc3Ryb2tlLWRhc2hvZmZzZXQ9IjAiIGZvbnQtZmFtaWx5PSJub25lIiBmb250LXdlaWdodD0ibm9uZSIgZm9udC1zaXplPSJub25lIiB0ZXh0LWFuY2hvcj0ibm9uZSIgc3R5bGU9Im1peC1ibGVuZC1tb2RlOiBub3JtYWwiPjxwYXRoIGQ9Ik0wLDE3MnYtMTcyaDE3MnYxNzJ6IiBmaWxsPSJub25lIj48L3BhdGg+PGcgZmlsbD0iI2ZmZmZmZiI+PHBhdGggZD0iTTcyLjI0LDEwLjMyYy0zMi4zMzA2MiwwIC01OC40OCwyNi4xNDkzOCAtNTguNDgsNTguNDhjMCwzMi4zMzA2MyAyNi4xNDkzOCw1OC40OCA1OC40OCw1OC40OGMxMS41NDI4MSwwIDIyLjIyNTYzLC0zLjM4NjI1IDMxLjI4MjUsLTkuMTM3NWw0Mi4yNDc1LDQyLjI0NzVsMTQuNjIsLTE0LjYybC00MS43MSwtNDEuNjAyNWM3LjQ5ODEzLC05LjgzNjI1IDEyLjA0LC0yMi4wMjQwNiAxMi4wNCwtMzUuMzY3NWMwLC0zMi4zMzA2MiAtMjYuMTQ5MzcsLTU4LjQ4IC01OC40OCwtNTguNDh6TTcyLjI0LDI0LjA4YzI0Ljc2NTMxLDAgNDQuNzIsMTkuOTU0NjkgNDQuNzIsNDQuNzJjMCwyNC43NjUzMSAtMTkuOTU0NjksNDQuNzIgLTQ0LjcyLDQ0LjcyYy0yNC43NjUzMSwwIC00NC43MiwtMTkuOTU0NjkgLTQ0LjcyLC00NC43MmMwLC0yNC43NjUzMSAxOS45NTQ2OSwtNDQuNzIgNDQuNzIsLTQ0LjcyeiI+PC9wYXRoPjwvZz48L2c+PC9zdmc+"/>
                            </Button>
                        </Form>
                        {searchProduct !== '' ?
                            <ListGroup className="hidden-lg mt-2">{matches}</ListGroup>:
                            <></>
                        }
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
            <Row className="justify-content-right mt-1 hidden-md fixed-top">
                <Col xs={2} className="ml-auto mr-3">
                    {searchProduct !== '' ?
                        <ListGroup>{matches}</ListGroup>:
                        <></>
                    }
                </Col>
            </Row>
            
            {/* <hr className="line"/> */}
        </>
    );
}

export default AppNavbar;
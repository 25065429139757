import { useContext, useEffect } from "react";
import { Redirect } from "react-router";
import UserContext from "../ContextUser";

const Logout = () => {
    const {unsetUser, setUserDetails} = useContext(UserContext)
    unsetUser();
    useEffect(() => {
        setUserDetails({
            id: null,
            isAdmin: null,
            orders: {
              product: [null],
              totalAmount: null
            }
          })
    })
    return (
        <>
            <Redirect to="/login"/>
        </>
    );
}

export default Logout;
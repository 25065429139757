import { useEffect, useState } from "react";
import { Button, Card, Col, Form, ListGroup, Row } from "react-bootstrap";

import './adminTools.css'

const Update = (refresTab) => {
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [price, setPrice] = useState('');
    // BUTTON ACTIVITY
    const [isButtonActive, setButtonActivity] = useState(false)
    // COLUMNS
    const [products, setProducts] = useState([]);
    const [render, setRender] = useState([]);
    // CARD
    const [selectedProduct, setSelectedProduct] = useState('');
    const [productId, setProductId] = useState('');
    const [tabRefresher, setTabRefresher] = useState(false)

    const buttonCondition = name !== '' && description !== '' && productId !== '' && price !== '';

    useEffect(() => {
        if (buttonCondition) {
            setButtonActivity(true)
        } else {
            setButtonActivity(false)
        }
    },[name, description, price, productId])

    const updateProduct = (event) => {
        event.preventDefault()
        const data = {
            method: "PUT",
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                id: productId,
                name: name,
                description: description,
                price: price
            })
        }
        fetch('https://ecomm-api.xhunn.dev/products/update', data).then(result => {
            if (result.ok) {
                return result.json()
            } else {
                throw new Error('Error occured')
            }
        }).then(updatedOutput => {
            if (tabRefresher) {
                setTabRefresher(false)
            } else {
                setTabRefresher(true)
            }
            setName('')
            setDescription('')
            setPrice('')
            setSelectedProduct(
                <>
                    <Row className="mb-5">
                        <Col>
                            <Card className="product-selected-card">
                                <Card.Header className="product-selected-inner">
                                    <h4>{updatedOutput.product.name}</h4>
                                </Card.Header>
                                <Card.Body className="product-selected-inner">
                                    <p>{updatedOutput.product.description}</p>
                                    <p>PHP {updatedOutput.product.price}</p>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="text-center text-success">
                            <p>{updatedOutput.response}</p>
                        </Col>
                    </Row>
                </>
                )
        }).catch(error => console.error(error))
    }

    const select = (event) => {
        const data = {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                id: event.currentTarget.value
            })
        }
        fetch('https://ecomm-api.xhunn.dev/products/selected', data)
            .then(result => {
                if (result.ok) {
                    return result.json()
                } else {
                    throw new Error('Error occured')
                }
            })
            .then(product => {
                console.log(product)
                setProductId(product._id)
                setSelectedProduct(
                    <>
                        <Row className="mb-5">
                            <Col>
                                <Card className="product-selected-card">
                                    <Card.Header className="product-selected-inner">
                                        <h4>{product.name}</h4>
                                    </Card.Header>
                                    <Card.Body className="product-selected-inner">
                                        <p>{product.description}</p>
                                        <p>PHP {product.price}</p>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </>
                )
            }).catch(error => console.log(error))
    }

    useEffect(() => {
        fetch('https://ecomm-api.xhunn.dev/products/all')
            .then(dataFetch => dataFetch.json())
            .then(productsJSON => setProducts(productsJSON.map(eachProduct => {
            return (
                <>
                    <ListGroup.Item action onClick={event => select(event)} className="product-item" key={eachProduct._id} value={eachProduct._id}>
                            {eachProduct.name}
                    </ListGroup.Item>
                </>
            );
        })))
        setRender(products)
    }, [render, refresTab, tabRefresher])

    return (
        <>
            <Row className="my-5">
                <Col xs={12} md={5} className="mr-5">
                    {selectedProduct}
                    <Form onSubmit={event => updateProduct(event)}>
                        <Form.Row>
                            <Form.Group as={Col} controlId="Name:">
                                <Form.Label>Name: </Form.Label>
                                <Form.Control className="text-bar" type="text" value={name} placeholder="Enter product name" required onChange={value => setName(value.target.value)}/>
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} controlId="Description:">
                                <Form.Label>Description: </Form.Label>
                                <Form.Control className="text-bar" as='textarea' rows={4} value={description} placeholder="describe your product" required onChange={value => setDescription(value.target.value)}/>
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} controlId="Price:">
                                <Form.Label>Price: </Form.Label>
                                <Form.Control className="text-bar" type="number" value={price} placeholder="Indicate how much" required onChange={value => setPrice(value.target.value)}/>
                            </Form.Group>
                        </Form.Row>
                        {isButtonActive ? 
                            <Button type="submit" id="submitLogin" className="btn btn-block success">Update</Button> :
                            <Button type="submit" id="submitLogin" className="btn btn-block disabled" disabled>Please fill out</Button>
                            
                        }
                    </Form>
                </Col>
                <Col xs={12} md={5} className="col-right">
                    <ListGroup className="ml-5">
                        {render && products}
                    </ListGroup>
                </Col>
            </Row>
        </>
    )
}

export default Update;
import { useEffect, useState } from "react";
import { Button, Card, Col, Container, Image, Row } from "react-bootstrap";
import Footer from "../Footer/Footer";
import cart from './addToCart.png'
import './Products.css'

// https://www.pinterest.ph/pin/9922061669355889/
// https://www.pinterest.ph/pin/3588874690632274/

const Selected = () => {
    const id = localStorage.getItem('search-item')
    const [product, setProduct] = useState({});
    const { name, description, createdOn, price, _id } = product;

    // HOOKS
    const [quantity, setQuantity] = useState(0);


    const data = {
        method: "POST",
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            id: id
        })
    }
    useEffect(() => {
        fetch('https://ecomm-api.xhunn.dev/products/selected', data)
            .then(result => result.json())
            .then(data => {
                setProduct(data)
            })
    }, []);
    return (
        <>
            <Row className="justify-content-center mt-5">
                <Col xs={12} md={3}>
                    <Image fluid className="selected-image" src="sampleOne.jpg" alt="place value for our image"/>
                </Col>
                <Col xs={12} md={7}>
                    <Card className="card-style">
                        <Card.Header className="card-header pt-3">
                            <Card.Title><h2 className="display-4">{name}</h2></Card.Title>
                        </Card.Header>
                        <Card.Body>
                            <Card.Text>{description}</Card.Text>
                            <Card.Text>Created On: {createdOn}</Card.Text>
                        </Card.Body>
                        <Card.Footer className="mt-3 pt-4">
                            <Row className="justify-content-between">
                                <Col className="ml-2">
                                    <Card.Subtitle>PHP {price}</Card.Subtitle>
                                </Col>
                                <Col className="mr-2 text-right">
                                    <Card.Subtitle>Quantity: {quantity}</Card.Subtitle>
                                </Col>
                            </Row>
                        </Card.Footer>
                    </Card>
                    <Container className="mt-3 text-right">
                        <Button as={Col} sm={12} md={1} className="mx-2 p-0 success d-inline-flex align-items-center justify-content-center" onClick={() => {
                            if (quantity >= 1) {
                                return setQuantity(quantity - 1)
                            }
                            }}>-</Button>
                        <Button as={Col} sm={12} md={1} className="mx-2 p-0 success d-inline-flex align-items-center justify-content-center" onClick={() => setQuantity(quantity + 1)}>+</Button>
                        <Button as={Col} sm={12} md={1} className="mx-2 p-0 success d-inline-flex align-items-center justify-content-center"><Image height="20px" src={cart} alt="cart" /></Button>
                    </Container>
                </Col>
            </Row>
            <Footer/>
        </>
    );
}

export default Selected;
import { useContext, useEffect, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import Banner from '../Components/Banner/Banner';
import Products from '../Components/Products/Products';
import Footer from '../Components/Footer/Footer';
import { useHistory } from 'react-router';
import UserContext from '../ContextUser';

let info = {
    title: "Shop now!",
    tagline: "View and purchase all available products here!"
}
const Catalogue = () => {
    // HOOKS
    const [product, setProduct] = useState([]);
    const [refresher, setRefesher] = useState(false)
    const { userDetails } = useContext(UserContext);
    let productOne = [];
    let productTwo = [];

    for (let index = 0; index < product.length; index++) {
        if (index === 0) {
            productOne.push(product[index])
        } else if ((index % 2) === 0) {
            productOne.push(product[index])
        } else {
            productTwo.push(product[index])
        }
    }

    // CONDITIONS
    //const isNewRow = (subjectCounter % 3 === 0)
    useEffect(() => {
        fetch('https://ecomm-api.xhunn.dev/products/available')
            .then(result => result.json())
            .then(convertedData => {
            setProduct(convertedData.map(products => {
                return (
                    <>
                        <Products key={products._id} infos={products} refresher={refresher}/>
                    </>
                );
            }))
        });
    }, [refresher])

    useEffect(() => {
        console.log(userDetails)
    }, [])

    const clearCart = (event) => {
        event.preventDefault()
        const data = {
            method: 'PUT',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify([])
        }
        fetch('https://ecomm-api.xhunn.dev/products/addToCart', data)
            .then(result => {
                if (result.ok) {
                    return result.json()
                } else {
                    throw new Error('Error occured')
                }
            }).then(cartResult => {
                console.log(cartResult)
                if (refresher) {
                    setRefesher(false)
                } else {
                    setRefesher(true)
                }
            }).catch(error => console.error(error))
        window.location.reload(true)
    }
    
    return (
        <>
            <Row className="justify-content-around">
                <Col xs={3}>
                    <Banner props={info} />
                </Col>
                <Col xs={5} className="d-flex align-items-center">
                    <Button block className="success" onClick={clearCart}>Clear Cart</Button>
                </Col>
            </Row>
            <Row className="justify-content-center">
                <Col xs={5}>
                    {productOne}
                </Col>
                <Col xs={5}>
                    {productTwo}
                </Col>
            </Row>
            <Footer/>
        </>
    );
}

export default Catalogue;
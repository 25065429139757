import { useState, useEffect } from "react";
import { Form, Button, Container, Col } from "react-bootstrap";
import { useHistory } from "react-router";
import Swal from "sweetalert2";
import Banner from '../Components/Banner/Banner';
import Footer from '../Components/Footer/Footer';
import './pages.css'



const Register = () => {
    let info = {
        title: "Register",
        tagline: "Create an account and be a member with us!"
    }
    
    const history = useHistory();
    // HOOKS
    const [firstName, setFirstName] = useState("");
    const [middleName, setMiddleName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [passwordOne, setPasswordOne] = useState("");
    const [passwordTwo, setPasswordTwo] = useState("");
    const [mobileNo, setMobileNo] = useState("");
    const [checkStartNumber, setStartNumber] = useState("");
    const [gender, setGender] = useState("Select gender here");

    // CONDITIONS VARIABLES - HOOKS
    const [isComplete, setIsComplete] = useState(false);
    const [isMatched, setIsMatched] = useState(false);
    const [isRegisterBtnActive, setRegisterBtn] = useState('');

    // CONDITIONS
    const isMobileNoRight = (checkStartNumber === '0995' || checkStartNumber === '0917' || checkStartNumber === '0998' || checkStartNumber === '0908' || checkStartNumber === '0961' || checkStartNumber === '0999' || checkStartNumber === '0919');
    const isInfoFilled = (firstName !== '' && middleName !== '' && lastName !== '' && email !== '' && passwordOne !== '' && passwordTwo !== '' && mobileNo !== '');

    const globe = ['0817', '0917']

    // FUNCTIONS
    const checkNumber = (value) => {
        let number = value.target.value;
        number = String(number)
        if (number.length > 11) {
            number = number.slice(0, 11)
            number = parseInt(number)
            setMobileNo(number)
        } else {
            setMobileNo(number)
        }
        number = String(number);
        number = number.slice(0, 4);
        setStartNumber(number);
    }
    const fetchData = {
        method: "POST",
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            firstName: firstName,
            middleName: middleName,
            lastName: lastName,
            email: email,
            password: passwordOne,
            gender: gender,
            mobileNo: mobileNo
        })
    }
    // POST REGISTRATION
    const registerUser = (event) => {
        event.preventDefault();
        fetch(`https://ecomm-api.xhunn.dev/users/register`, fetchData)
            .then(result => result.json())
            .then(data => {
                Swal.fire({
                    title: `Successfully registered!`,
                    icon: "success",
                    text: "Please login to further proceed"
                })
                history.push('/login');
            })
    }

    useEffect(() => {
        if (isInfoFilled) {
            if (isMobileNoRight) {
                if (String(mobileNo).length === 11) {
                    setIsComplete(true);
                    setRegisterBtn(true);
                } else {
                    setIsComplete(false);
                    setRegisterBtn(false);
                }
            } else {
                setIsComplete(false);
                setRegisterBtn(false);
            }
        } else {
            setIsComplete(false);
            setRegisterBtn(false);
        }
    }, [isInfoFilled, isMobileNoRight, mobileNo])
    
    useEffect(() => {
        if (passwordOne === passwordTwo && passwordOne !== '') {
            setIsMatched(true)
        } else {
            setIsMatched(false)
        }
    }, [passwordOne, passwordTwo])

    return (
        <>
            <Container fluid className="d-flex justify-content-center">
                <img src="CartUp.png" height="100px" alt="CART-UP" className="mt-5" />
            </Container>
            <Container>
                <Banner props={info} />
                {/* <img src="photo1.jpg" alt="image not found"/> */}
                {isComplete ? 
                <p className="mb-5 lead text-success"> Proceed with Register</p>
                : <p className="mb-5 lead"> Fill up the form below to proceed</p>}
                <Form className="mb-5" onSubmit={event => registerUser(event)}>
                    <Form.Row>
                        {/* First Name */}
                        <Form.Group as={Col} controlId="firstName">
                            <Form.Label>First Name:</Form.Label>
                            <Form.Control className="text-bar" type="text" value={firstName} placeholder="Please enter your first name" required onChange={value => setFirstName(value.target.value)}/>
                        </Form.Group>
                        {/* Middle Name */}
                        <Form.Group as={Col} controlId="middleName">
                            <Form.Label>Middle Name:</Form.Label>
                            <Form.Control className="text-bar" type="text" value={middleName} placeholder="Please enter your middle name" required onChange={value => setMiddleName(value.target.value)}/>
                        </Form.Group>
                        {/* Last Name */}
                        <Form.Group as={Col} controlId="lastName">
                            <Form.Label>Last Name:</Form.Label>
                            <Form.Control className="text-bar" type="text" value={lastName} placeholder="Please enter your last name" required onChange={value => setLastName(value.target.value)}/>
                        </Form.Group>
                    </Form.Row>
                    {/* Email */}
                    <Form.Group controlId="email">
                        <Form.Label>Email Address:</Form.Label>
                        <Form.Control className="text-bar" type="email" value={email} placeholder="Please enter your email" required onChange={value => setEmail(value.target.value)}/>
                    </Form.Group>
                    <Form.Row>
                        {/* Password */}
                        <Form.Group as={Col} controlId="password">
                            <Form.Label>Password:</Form.Label>
                            {passwordOne === "" && passwordTwo === "" ?
                                <strong className="text-success"></strong> :

                                isMatched ?
                                    <strong className="text-success"> Password match!</strong> :
                                    <strong className="text-danger"> Password should match</strong> 
                        
                            }
                            <Form.Control className="text-bar" type="password" value={passwordOne} placeholder="Please enter your desired password" required onChange={value => setPasswordOne(value.target.value)}/>
                        </Form.Group>
                        <Form.Group className="text-bar" as={Col} controlId="password2">
                            <Form.Label>Confirm Password:</Form.Label>
                            <Form.Control className="text-bar" type="password" value={passwordTwo} placeholder="Please confirm your password" required onChange={value => setPasswordTwo(value.target.value)}/>
                        </Form.Group>
                    </Form.Row>
                    <Form.Group controlId="gender">
                        <Form.Label>Gender:</Form.Label>
                        <Form.Control className="text-bar" as="select" onChange={value => setGender(value.target.value)}>
                            <option selected disabled value="none">Select gender here</option>
                            <option value="male">Male</option>
                            <option value="female">Female</option>
                        </Form.Control>
                    </Form.Group>
                    {/* Mobile Number */}
                    <Form.Group>
                        <Form.Label>Mobile Number: </Form.Label>
                        {mobileNo === "" ?
                            <strong className="text-success"></strong> :
                            isMobileNoRight ?
                                String(mobileNo).length === 11 ? 
                                    <strong></strong> : <strong className="text-danger"> 11-digit number is needed </strong>
                                :<strong className="text-danger"> Please input a valid number </strong> 
                        }
                        <Form.Control type="number" className="text-bar" value={mobileNo} pattern="[0-9]" maxLength="11" required onChange={checkNumber}/>
                    </Form.Group>
                    {/* Alternative with "-" */}
                    {/* <Form.Group controlId="mobileNo">
                        <Form.Label>Mobile Number: (09XXXXXXXXX)</Form.Label>
                        <Form.Control type="tel" pattern="[0]{1}[9]{1}[0-9]{9}}"placeholder="Please enter your phone number" required />
                    </Form.Group> */}
                    {isRegisterBtnActive ? <Button type="submit" className="btn btn-block success" id="submitRegister">Create an account</Button> : <Button type="submit" className="btn btn-block disabled" id="submitRegister" disabled>Create an account</Button>}
    
                </Form>
            </Container>
            <Footer/>
        </>

    );
};

export default Register;
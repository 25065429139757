import { useEffect, useState } from "react";
import { Col, Form, Button, Row, Card } from "react-bootstrap";
import './adminTools.css'

const Create = (refresher) => {
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [price, setPrice] = useState('');

    const [createdProduct, setCreatedProduct] = useState();

    const [isRegisterBtnActive, setRegisterBtnActivity] = useState(false);
    const buttonCondition = name !== '' && description !== '' && price !== '';

    useEffect(() => {
        if (buttonCondition) {
            setRegisterBtnActivity(true)
        } else {
            setRegisterBtnActivity(false)
        }
    }, [name, description, price])

    const createProduct = (event) => {
        event.preventDefault();
        const data = {
            method: "POST",
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                name: name,
                description: description,
                price: price
            })
        }
        fetch('https://ecomm-api.xhunn.dev/products/add', data)
            .then(result => {
                if (result.ok) {
                    return result.json()
                } else {
                    throw new Error('Error has occured')
                }
            }).then(createdProduct => {
                console.log(createdProduct.response)
                setName('')
                setDescription('')
                setPrice('')
                setCreatedProduct(
                    <>
                        <Card className="product-selected-card">
                            <Card.Header className="product-selected-inner">
                                <Card.Title>{createdProduct.product.name}</Card.Title>
                            </Card.Header>
                            <Card.Body className="product-selected-inner">
                                <Card.Text>{createdProduct.product.description}</Card.Text>
                                <Card.Subtitle>PHP {createdProduct.product.price}</Card.Subtitle>
                            </Card.Body>
                        </Card>
                        <p className="lead text-center text-success">{createProduct.response}</p>
                    </>
                )
            }).catch(error => console.error(error))
    }

    return (
        <>
            <Row>
                <Col xs={12} md={5} className="mr-3">
                    <Form onSubmit={event => createProduct(event)}>
                        <Form.Row>
                            <Form.Group as={Col} controlId="Name:">
                                <Form.Label>Name: </Form.Label>
                                <Form.Control className="text-bar" type="text" value={name} placeholder="Enter product name" required onChange={value => setName(value.target.value)}/>
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} controlId="Description:">
                                <Form.Label>Description: </Form.Label>
                                <Form.Control className="text-bar" as='textarea' rows={4} value={description} placeholder="describe your product" required onChange={value => setDescription(value.target.value)}/>
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} controlId="Price:">
                                <Form.Label>Price: </Form.Label>
                                <Form.Control className="text-bar" type="number" value={price} placeholder="Indicate how much" required onChange={value => setPrice(value.target.value)}/>
                            </Form.Group>
                        </Form.Row>
                        {isRegisterBtnActive ? <Button type="submit" className="btn btn-block success" id="submitRegister">Create an account</Button> : <Button type="submit" className="btn btn-block disabled" id="submitRegister" disabled>Please fill out</Button>}
                    </Form>
                </Col>
                <Col xs={12} md={5} className="d-flex justify-content-center align-items-center col-right">
                    {createdProduct}
                </Col>
            </Row>
            
        </>
    )
}

export default Create;
import AppNavbar from './Components/AppNavbar/AppNavbar';
import Home from './Pages/Home';
import Catalogue from './Pages/Catalog';
import Login from './Pages/Login';
import Register from './Pages/Register';
import Error from './Pages/Error';
import Selected from './Components/Products/Selected';
import AdminPanel from './Pages/AdminPanel';
import UserContext from './ContextUser';
import { Route, Switch, BrowserRouter as Router } from 'react-router-dom';
import { useEffect, useState } from 'react';
import './App.css'
import Logout from './Pages/Logout';
import Cart from './Pages/Cart';
import Credits from './Pages/Credits';


function App() {
  const [userDetails, setUserDetails] = useState({
    id: null,
    isAdmin: null,
    cart: null
  });

  const unsetUser = () => {
    localStorage.clear()
    setUserDetails({
      id: null,
      isAdmin: null,
      cart: null
    })
  }

  useEffect(() => {
    const authorization = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`
      }
    }
    fetch('https://ecomm-api.xhunn.dev/users/details', authorization)
      .then(detailsPromise => {
        if (detailsPromise.ok) {
          return detailsPromise.json()
        } else {
          throw new Error('Error Occured')
        }
      })
      .then(details => {
        if (details._id !== null || details._id !== undefined || details._id !== 'undefined') {
          console.warn(details)
          setUserDetails({
            id: details._id,
            isAdmin: details.isAdmin,
            cart: details.cart
          })
        } else {
          setUserDetails({
            id: null,
            isAdmin: null,
            cart: null
          })
        }
      }).catch(error => console.error(error))
  }, [])

  return (
    <Router>
        <UserContext.Provider value={{ userDetails, setUserDetails, unsetUser}}>
          <AppNavbar/>
          <section className="background">
            <Switch>
              <Route exact path="/" component={Home} />
              <Route exact path="/shop" component={Catalogue} />
              <Route exact path="/login" component={Login} />
              <Route exact path="/register" component={Register} />
              <Route exact path="/selected" component={Selected} />
              <Route exact path="/adminTools" component={AdminPanel} />
              <Route exact path="/cart" component={Cart} />
              <Route exact path="/logout" component={Logout} />
              <Route exact path="/credits" component={Credits} />
              <Route component={Error}/>
            </Switch>
          </section>
        </UserContext.Provider>
    </Router>
  );
}

export default App;

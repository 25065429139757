import { useContext, useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import Footer from "../Components/Footer/Footer";
import UserContext from "../ContextUser";

const Cart = () => {
    const { userDetails, cart, setCart } = useContext(UserContext);
    const [productShowcase, setProductShowcase] = useState([]);
    const [toCompute, setCompute] = useState([]);
    const [grandTotal, setGrandTotal] = useState([]);
    const [renderOne, setRenderOne] = useState([]);
    const [renderTwo, setRenderTwo] = useState([]);
    
    useEffect(() => {
        for (let i = 0; i < userDetails.cart.length; i++) {
            
            let data = {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    id: userDetails.cart[i].productId
                })
            }
            fetch('https://ecomm-api.xhunn.dev/products/selected', data)
                .then(result => {
                    if (result.ok) {
                        return result.json()
                    } else {
                        throw new Error('Error occured')
                    }
                }).then(eachProduct => {
                    let totalPriceForItem = cart.quantity * eachProduct.price
                    setCompute(totalPriceForItem)
                    setProductShowcase(
                        <>
                            <Card className="card-style">
                                <Card.Header>
                                    <Card.Title className="card-inside">
                                        <h3>{eachProduct.name}</h3>
                                    </Card.Title>
                                </Card.Header>
                                <Card.Body className="card-inside">
                                    <Card.Text>PHP {eachProduct.price}</Card.Text>
                                    <Card.Text>Quantity: {cart.quantity}</Card.Text>
                                </Card.Body>
                                <Card.Footer className="card-inside">
                                    <Card.Subtitle>Total: {totalPriceForItem}</Card.Subtitle>
                                </Card.Footer>
                            </Card>
                        </>
                    )
                }).catch(error => console.error(error))
        }
        for (let i = 1; i < (productShowcase.length + 1); i++) {
            setGrandTotal(grandTotal + toCompute[i])
            if (i % 2 === 0) {
                setRenderOne(productShowcase[i])
            } else {
                setRenderTwo(productShowcase[i])
            }
        }
    }, [])


    return (
        <>
            <Row>
                <Col xs={12} md={5}>
                    {renderOne}
                </Col>
                <Col xs={12} md={5}>
                    {renderTwo}
                </Col>
            </Row>
            <Row>
                <Col>
                    <p className="lead">Grand Total: {grandTotal}</p>
                </Col>
            </Row>
            <Footer/>
        </>
    )
}

export default Cart;